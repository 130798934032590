<template>
  <div class="mt-16">
    <h1 class="text-center">
      Login
    </h1>

    <p class="mt-4 text-center text-sm text-gray-600">
      or

      <nuxt-link class="font-medium link lowercase" to="/register">
        register
      </nuxt-link>
    </p>

    <div class="mt-4 sm:mx-auto sm:w-full sm:max-w-lg border rounded-xl">
      <div class="space-y-6 bg-white sm:rounded-xl py-8 px-4 sm:px-10">
        <forms-login/>

        <auth-socials-providers/>

        <div class="text-center">
          <nuxt-link class="font-medium link text-sm" to="/forgot/password">
            Did you forget your password?
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
definePageMeta({
  middleware: ['guest'],
})

const route = useRoute()
const router = useRouter()

onMounted(async () => {
  if (route.query.token) {
    const token = useCookie('token')
    token.value = route.query.token

    let query = Object.assign({}, route.query)
    delete query.token

    await router.replace({query})

    location.reload()
  }
})
</script>
