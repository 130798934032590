<template>
  <form class="space-y-6"
        @submit.prevent="login">
    <inputs-email
        id="email_address"
        autofocus
        placeholder="joe@doe.com"
        required
        title="Email Address"
        @set="set"/>

    <inputs-password id="password"
                     placeholder="******"
                     required
                     title="Password"
                     @set="set"/>

    <button :class="{'opacity-50 cursor-not-allowed': disable_login_button}" :disabled="disable_login_button"
            class="btn-primary-md w-full"
            type="submit">
      Login
    </button>
  </form>
</template>

<script setup>
import {authStore} from "~/stores/auth.js"

const form = reactive({
  email_address: '',
  password: '',
})

const disable_login_button = ref(false)

async function login() {
  disable_login_button.value = true

  await authStore()
      .login(form.email_address, form.password)
      .catch(() => disable_login_button.value = false)

  disable_login_button.value = false
}

function set(event) {
  form[event.target.id] = event.target.value
}
</script>
